/* h2 {
  font-size: 4.5vw!important;
}

p {
  font-size: 3vw!important;
} */

h2 {
  color: #83d95b;
  font-size: 24px;
  margin: 0;
}

.portal__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portal__loading-heading {
  font-size: 24px;
}

.portal__message-list {
  list-style-type: none;
  max-height: 300px;
  overflow: auto;
  white-space: pre-line;
  width: 90%;
  margin: 10px auto;
  border: 2px solid #6dd93b;
  color: #d7d7d9;
}

.portal__message-list > li.portal__message-item:nth-child(even) {
  border-top: 1px solid #d7d7d9;
  border-bottom: 1px solid #d7d7d9;
}

.portal__message-list > li {
  width: 98%;
  margin: 0 auto;
  padding: 5px 0;
}
.portal__expiry-datetime {
  font-size: 16px;
  color: #d7d7d9;
  text-align: center;
  margin: 4px auto;
}
.portal__close-session {
  background-color: #83d95b;
  color: #3e3740;
  cursor: pointer;
  border: 2px solid #6dd93b;
  border-radius: 10px;
  padding: 4px;
}

.portal__empty-messages {
  width: 100%;
  text-align: center;
  color: #d7d7d9;
  margin: 24px auto;
  border: 2px solid #6dd93b;
  padding: 5px 0;
}

.portal__gate-form {
  color: #d7d7d9;
}

.portal__gate-form > label > input {
  margin-left: 5px;
}

.portal__error {
  text-align: center;
  color: red;
}

@media screen and (min-width: 768px) {
  .portal__container {
    flex-direction: unset;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }
  .portal__title {
    margin: 0;
  }

  .portal__message-list {
    max-height: 450px;
  }

  .portal__gate-form {
    margin: 30% auto;
  }
}

@media screen and (min-width: 1024px) {
  .portal__heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 12px 5%;
  }

  .portal__message-list-heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .portal__title {
    font-size: 48px;
  }

  .portal__expiry-datetime {
    font-size: 18px;
  }

  .portal__empty-messages {
    align-self: flex-start;
    width: 55%;
    height: 500px;
    padding-top: 30px;
  }

  .portal__message-list {
    list-style-type: none;
    align-self: flex-start;
    margin-bottom: 10%;
    height: 500px;
    width: 55%;
  }

  .portal__container {
    display: flex;
    flex-direction: row;
  }

  .portal__gate-form {
    margin: 20% auto;
  }

  .portal__gate-form > label {
    font-size: 18px;
  }
  .portal__gate-form > label > input {
    padding: 6px;
  }
}
