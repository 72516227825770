.howto__container > header > h2 {
  color: #83d95b;
  font-size: 24px;
}

ol.howto__overview {
  color: #d7d7d7;
  padding-left: 32px;
  font-size: 16px;
}

ol.howto__overview > li {
  margin: 16px auto;
}

ol.howto__overview > li > p {
  font-size: 16px;
  text-align: left;
}

ol.howto__overview > li > ul {
  margin: 0 16px;
}

ol.howto__overview > li > ul > li {
  text-align: left;
  margin: 5px auto;
  font-size: 12px;
}

@media screen and (min-width: 1024px) {
  .howto__container {
    width: 50%;
    margin: 0 auto;
  }

  .howto__container > header > h2 {
    font-size: 36px;
  }

  ol.howto__overview > li > p {
    font-size: 18px;
  }

  ol.howto__overview > li > ul > li {
    font-size: 16px;
  }
}
