p.portal__message-content {
  font-size: 12px;
  word-wrap: break-word;
  text-align: left;
  margin: 8px;
}

p.portal__message-info {
  font-size: 10px;
  text-align: right;
  margin: 8px;
}

@media screen and (min-width: 1024px) {
  p.portal__message-content {
    font-size: 18px;
  }

  p.portal__message-info {
    font-size: 12px;
  }
}
