html {
  background-color: #3e3740;
  min-height: 100vh;
}

.App {
  text-align: center;
  font-family: 'Nunito', sans-serif;
  padding: 0 10px;
}

.App-link {
  color: #09d3ac;
}

h1 {
  color: #d7d7d9;
  font-size: 64px;
  border-bottom: 5px solid #6dd93b;
  display: inline;
}

.App-header {
  margin: 50px auto;
}
.App-header > a {
  color: #6dd93b;
  text-decoration: none;
}

.App__error {
  text-align: center;
  color: red;
}

@media screen and (min-width: 768px) {
  html {
    width: 80%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1024px) {
  h1 {
    font-size: 88px;
  }
}
