.portal__form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin: 10px;
  padding: 0 16px;
  color: #d7d7d9;
}

.portal__form > button {
  background-color: #3e3740;
  color: #6dd93b;
  font-size: 18px;
  width: 90%;
  margin: 20px auto;
  border: 2px solid #6dd93b;
  border-radius: 10px;
  padding: 10px;
}

.portal__form > label {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.portal__form > label > select {
  width: 98%;
}

.portal__form > label > input {
  width: 97%;
}

.portal__form > label > select,
.portal__form > label > input {
  background-color: #d7d7d9;
  margin-top: 5px;
  text-align: center;
  text-align-last: center;
  border: 2px solid #d7d7d9;
  border-radius: 5px;
}

.portal__form > label > select:focus,
.portal__form > label > input:focus {
  outline: unset;
  box-shadow: 0 0 3pt 2pt #6dd93b;
}

.portal__form--password-container {
  width: 98%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
label.portal__form--checkbox,
input.portal__form--checkbox {
  margin: 10px;
}

.portal__form--error {
  color: red;
}

@media screen and (min-width: 1024px) {
  .portal__form {
    width: 40%;
    margin: 0 auto;
  }
  .portal__form > label > select,
  .portal__form > label > input {
    height: 35px;
  }
  .portal__form > button {
    padding: 20px 10px;
  }
}
