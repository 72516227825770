.portal__message-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
  margin: 5px auto;
  color: #d7d7d9;
}

.portal__message-form > button {
  background-color: #3e3740;
  color: #6dd93b;
  width: 90%;
  margin: 20px auto;
  border: 2px solid #6dd93b;
  border-radius: 10px;
  padding: 10px;
}

.portal__message-form > label {
  width: 98%;
  margin-bottom: 5px;
  padding-bottom: 5px;
}
.portal__message-form > label > textarea,
.portal__message-form > label > input {
  background-color: #d7d7d9;
  width: 98%;
  margin-top: 5px;
  text-align: center;
  text-align-last: center;
  border: 2px solid #d7d7d9;
  border-radius: 5px;
}

.portal__message-form > label > textarea {
  resize: none;
}

.portal__message-form > label > textarea:focus,
.portal__message-form > label > input:focus {
  outline: unset;
  box-shadow: 0 0 3pt 2pt #6dd93b;
}

.portal__message-form--password-container {
  width: 98%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
label.portal__message-form--checkbox,
input.portal__message-form--checkbox {
  margin: 10px;
}

.portal__message-form--error {
  color: red;
}

@media screen and (min-width: 1024px) {
  .portal__message-form {
    width: 30%;
    height: 500px;
    margin-bottom: 10%;
  }

  .portal__message-form > label > textarea {
    height: 300px;
    text-align: left;
    text-align-last: left;
  }
}
