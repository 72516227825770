.about__container {
  margin: 10px;
  padding: 2px;
}

.about__container > header > h2 {
  font-size: 24px;
  margin: 10px auto;
}
.about__container > section > p {
  color: #d7d7d7;
  font-size: 16px;
  margin: 5px;
  text-align: left;
}

@media screen and (min-width: 1024px) {
  .about__container {
    width: 50%;
    margin: 0 auto;
  }

  .about__container > header > h2 {
    font-size: 36px;
  }

  .about__container > section > p {
    font-size: 18px;
  }
}
