.nav__list {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 50px;
  font-size: 24px;
}

.nav__list > li {
  padding: 4px 2px;
  border: 2px solid #6dd93b;
  border-radius: 10px;
}

.nav__list > li > a {
  text-decoration: none;
  color: #6dd93b;
}

@media screen and (min-width: 768px) {
  .nav__list > li {
    padding: 12px 8px;
  }
}

@media screen and (min-width: 1024px) {
  .nav__list {
    width: 40%;
    margin: 0 auto;
    margin-bottom: 50px;
  }
  .nav__list > li:hover {
    background-color: #6dd93b;
  }
  .nav__list > li:hover > a {
    color: #3e3740;
  }
}
